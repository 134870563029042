<template>
	<div class="barcode-field-box">
		<button class="button style-3" @click.prevent="startScanner">{{ $t('forms.scan') }}</button>
	</div>
</template>

<script>
import {BarcodeScanner} from '@capacitor-community/barcode-scanner';

export default {
	name: "BarcodeField",
	methods: {
		sendValue(value) {
			this.$root.$emit('barcode-scanned', value);
		},
		async startScanner() {
			const permission = await BarcodeScanner.checkPermission({force: true});

			if (permission.denied) {
				this.$swal.fire({
					icon: 'info',
					title: 'Oops',
					text: this.$t('forms.permission_denied_camera'),
					confirmButtonText: this.$t('forms.open_settings'),
					cancelButtonText: this.$t('forms.cancel'),
					showCancelButton: true,
				}).then(async (result) => {
					if (result.isConfirmed) {
						await BarcodeScanner.openAppSettings();
					}
				})

				return false;
			}

			await this.$store.dispatch('openBarcodeOverlay');
			const result = await BarcodeScanner.startScan();

			if (result.hasContent) {
				this.sendValue(result.content);
				this.stopScanner();
			}
		},
		stopScanner() {
			this.$store.dispatch('closeBarcodeOverlay');
			BarcodeScanner.stopScan();
		}
	},
	mounted() {
		this.$root.$on('close-barcode-scanner', () => {
			this.stopScanner();
		});
	},
	destroyed() {
		this.$root.$off('close-barcode-scanner');
		this.stopScanner();
	}
}
</script>

<style scoped lang="scss">
.button {
	width: 100%;
}
</style>