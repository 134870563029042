<template>
	<main class="app-main">
		<div class="content-wrap">
			<section class="content-section">
				<header class="section-header">
					<h2 class="section-title">{{ $t('routes.investments_investika_account.title') }}:
						<span v-if="investmentsInvestikaAccount.accountName">{{ investmentsInvestikaAccount.accountName }} - </span>
						{{ investmentsInvestikaAccount.contract.accountNumber }}
					</h2>
				</header>
				<article>
					<actions-panel>
						<router-link class="button style-1" :to="$t('routes.investments_investika.path')">
							<span class="inner">{{ $t('general.back') }}</span>
						</router-link>
					</actions-panel>
					<chart-panels-group :panels-data="panelsData" :charts-data="chartsData"
					                    v-if="investmentsInvestikaAccount.portfolios.length" :tabs="true"/>
					<p v-else>{{ $t('investments.no_data') }}</p>
				</article>
			</section>
		</div>
	</main>
</template>

<script>
import investmentsMixin from "@/mixins/investmentsMixin";
import ActionsPanel from "@/components/panels/ActionsPanel";
import ChartPanelsGroup from "@/components/containers/ChartPanelsGroup";
import PanelBoxesContainer from "@/components/containers/PanelBoxesContainer";

export default {
	name: "InvestmentsInvestikaAccount",
	components: {
		ActionsPanel,
		ChartPanelsGroup,
		PanelBoxesContainer
	},
	mixins: [investmentsMixin],
	computed: {
		investmentsInvestikaAccount() {
			return this.$store.state.views.investmentsInvestikaAccount;
		},
		panelsData() {
			let data = [];
			this.investmentsInvestikaAccount.portfolios.forEach(item => {
				if (item.amount <= 0) {
					return;
				}

				let currency = this.getCurrencySymbol(item.currencyVAL);
				let transactions = this.investmentsInvestikaAccount.transactions.filter(transaction => {
					return transaction.financialInstrumentIsin === item.financialinstrumentisin;
				});
				let totalBalance = 0;
				transactions.forEach(transaction => {
					if (transaction.type === 'Odkup CP') {
						totalBalance -= parseFloat(transaction.amount);
					} else {
						totalBalance += parseFloat(transaction.amount);
					}
				});

				let returnPercentage = (parseFloat(item.amount) / totalBalance) * 100 - 100;

				data.push({
					id: item.financialinstrumentisin,
					category: this.$t('investments.portfolio'),
					name: item.financialinstrumentname,
					description: this.$t('investments.portfolio_balance'),
					price: parseFloat(item.amount),
					returnPercentage: returnPercentage,
					type: 'portfolio',
					portfolioType: 'investika',
					currency: currency,
					detail: !!item.financialinstrumentisin,
				});
			});

			return data;
		},
		chartsData() {
			let data = [
				this.balanceChart,
				this.portfoliosChart,
			];

			return data;
		},
		transactions(){
			let transactions = this.investmentsInvestikaAccount.transactions.sort((a, b) => {
				let dateA = new Date(a.settlementDate.split(' ')[0]);
				let dateB = new Date(b.settlementDate.split(' ')[0]);
				return dateA - dateB;
			});

			return transactions;
		},

		balanceChart() {
			let data = this.getBalanceChartOptions();
			data.tabName = this.$t('investments.profit');

			let series = [
				{
					name: this.$t('investments.account_balance'),
					data: [],
				},
				{
					name: this.$t('investments.transactions'),
					data: [],
				}
			];

			let currentDate = new Date().toISOString().slice(0, 10);
			this.investmentsInvestikaAccount.portfolio_history.forEach(item => {
				let amount = parseFloat(item.amount);
				let date = item.validFrom.split(' ')[0];
				series[0].data.push({
					x: date,
					y: amount.toFixed(2),
				});
			});


			let currentTransactionAmount = 0;
			this.transactions.forEach(item => {
				let dayBefore = new Date(item.settlementDate.split(' ')[0]);
				dayBefore.setDate(dayBefore.getDate() - 1);
				dayBefore = dayBefore.toISOString().slice(0, 10);

				series[1].data.push({
					x: dayBefore,
					y: currentTransactionAmount.toFixed(2),
				});

				let amount = parseFloat(item.amount);
				if (item.type === 'Odkup CP') {
					amount = amount * -1;
				}

				currentTransactionAmount += amount;
				let date = item.settlementDate.split(' ')[0];
				series[1].data.push({
					x: date,
					y: currentTransactionAmount.toFixed(2),
				});
			});

			series[1].data.push({
				x: currentDate,
				y: currentTransactionAmount.toFixed(2),
			});

			data.series = series;
			data.totalBalance = this.investmentsInvestikaAccount.summary.asset;
			data.totalDeposits = this.investmentsInvestikaAccount.summary.totalDeposits;
			data.totalInvestments = this.investmentsInvestikaAccount.summary.totalInvestments;
			data.totalWithdrawals = this.investmentsInvestikaAccount.summary.totalWithdraws;
			data.totalFees = this.investmentsInvestikaAccount.summary.paidCommissions;

			let totalReturn = parseFloat(this.investmentsInvestikaAccount.summary.asset) - (parseFloat(this.investmentsInvestikaAccount.summary.totalInvestments) - parseFloat(this.investmentsInvestikaAccount.summary.totalWithdraws))

			data.totalReturn = totalReturn;

			return data;
		},
		portfoliosChart() {
			let data = this.getPortfoliosChartOptions();
			data.tabName = this.$t('investments.allocation');

			let series = [];
			let labels = [];
			this.investmentsInvestikaAccount.portfolios.forEach(item => {
				series.push(item.amount);
				labels.push(item.financialinstrumentname);
			});

			data.chartOptions.labels = labels;
			data.series = series;

			return data;
		},
	}
}
</script>

<style scoped>

</style>