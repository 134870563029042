import { render, staticRenderFns } from "./ProtocolsSignForm.vue?vue&type=template&id=3ffd9100&scoped=true"
import script from "./ProtocolsSignForm.vue?vue&type=script&lang=js"
export * from "./ProtocolsSignForm.vue?vue&type=script&lang=js"
import style0 from "./ProtocolsSignForm.vue?vue&type=style&index=0&id=3ffd9100&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ffd9100",
  null
  
)

export default component.exports