<template>
	<div class="protocol-detail-box">
		<div class="protocol-data">
			<small class="protocol-detail-category">{{ category }}</small>
			<h3 class="protocol-detail-title">{{ title }} {{ data.popis }}</h3>
			<time class="date">{{ date }}</time>
			<icon-link-boxes-container :data="files"/>
			<p class="status" :class="`status-${data.status_dokumentu_id}`">{{ data.status_dokumentu }}</p>
			<protocols-sign-form v-if="legalsData" :data="legalsData" :hash="data.hash" :signatures="signatures"/>
		</div>
		<div class="actions">
			<button class="button style-4"
			        v-if="!showForm && statusToSign.includes(this.data.status_dokumentu_id)"
			        @click.prevent="getLegals"
			        :class="legalsLoading ? 'loading' : ''">
				<span class="inner">{{ $t('protocols.sign_protocol') }}</span>
			</button>
			<button class="button style-1" @click.prevent="closePopup">
				<span class="inner">{{ $t('forms.close')}}</span>
			</button>
		</div>
	</div>
</template>

<script>
	import protocolMixin from "../../mixins/protocolMixin";
	import IconLinkBoxesContainer from "./IconLinkBoxesContainer";
	import ProtocolsSignForm from "../forms/ProtocolsSignForm";

	export default {
		name: "ProtocolDetailBox",
		props: ['data'],
		mixins: [protocolMixin],
		components: {
			IconLinkBoxesContainer,
			ProtocolsSignForm
		},
		data() {
			return {
				showForm: false,
				loading: false,
				legalsLoading: false,
				errors: [],
				legalsData: false,
				signatures: [],
			}
		},
		mounted() {

		},
		computed: {
			files() {
				let files = [];

				this.data.files.forEach(item => {
					let editLink = false;
					if (item.type === 'questionnaire' && this.data.status_dokumentu_id === '1') {
						editLink = {
							anchor: true,
							target: '_blank',
							url: `${process.env.VUE_APP_PROTOCOLS_QUESTIONNAIRE_URL}/${this.data.hash}`
						};
					}

					files.push({
						icon: '<i class="fa-thin fa-file-pdf"></i>',
						name: item.name,
						url: item.url,
						anchor: true,
						target: '_blank',
						editLink
					});
				});

				return files;
			},
			category() {
				if (this.protocolType === 'eic') {
					return this.$t('protocols.eic_description');
				} else {
					return this.$t('protocols.protocol_description');
				}
			},
			title() {
				if (this.protocolType === 'eic') {
					return this.$t('protocols.eic_name');
				} else {
					return this.$t('protocols.protocol_name');
				}
			},
			date() {
				return this.$moment(this.data.vlozeny).format('D.M.YYYY hh:mm:ss');
			}
		},
		methods: {
			getLegals() {
				this.legalsLoading = true;
				this.axios.post('/api/protocols/get-legals', {
					hash: this.data.hash,
				}).then(response => {
					this.legalsData = response.data.legals.links[0];
					this.signatures = response.data.signatures;
					this.legalsLoading = false;
					this.showForm = true;
					console.log(this.signatures);
				}).catch((error) => {
					this.legalsLoading = false;
					if (!error.response.data.message) {
						this.$swal.fire({
							icon: 'error',
							title: 'Oops',
							text: this.$t('general.default_error')
						});
						return false;
					}

					this.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: error.response.data.message
					}).then(() => {
						if (!error.response.data.open_protocol) {
							return false;
						}

						let protocol = this.$store.state.views.protocols.find(item => {
							return item.hash === error.response.data.open_protocol;
						});

						if (!protocol) {
							return false;
						}

						if (this.$store.state.sidebars.showProtocolDetail) {
							this.$store.commit('HIDE_PROTOCOL_DETAIL');
							setTimeout(() => {
								this.$store.commit('SHOW_PROTOCOL_DETAIL', protocol);
							}, 400);
							return false;
						}
					});
				});
			},
			closePopup() {
				this.$store.commit('HIDE_PROTOCOL_DETAIL');
			}
		}
	}
</script>

<style scoped lang="scss">
	.protocol-detail-box {
		height: 100%;
		display: flex;
		flex-direction: column;

		.default-form {
			text-align: left;
		}

		.icon-link-boxes-container {
			margin-bottom: 25px;

			.icon-link {
				width: calc(50% - 10px);
				margin-right: 0;
			}
		}

		.status {
			text-transform: uppercase;
			font-size: 12px;
			font-weight: 600;

			&.status-1,
			&.status-4,
			&.status-6 {
				color: $primaryColor;
			}

			&.status-2 {
				color: $errorColor;
			}

			&.status-3,
			&.status-8 {
				color: $successColor;
			}
		}

		.cant-sign {
			color: var(--primary-color, $primaryColor);
			font-weight: 600;
			text-transform: uppercase;
			font-size: 12px;
			text-align: center;
		}

		.protocol-data {
			text-align: center;
			flex-grow: 1;
			margin-bottom: 20px;
		}

		.protocol-detail-category {
			font-weight: 600;
			font-size: 12px;
			text-transform: uppercase;
			margin-bottom: 20px;
			display: block;
		}

		.protocol-detail-title {
			font-size: 20px;
			margin-bottom: 20px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		.date {
			font-size: 12px;
			font-weight: 600;
			margin-bottom: 20px;
			display: block;
		}
	}

	.button {
		width: 100%;
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	@media($mobileM) {
		.protocol-detail-box {
			padding-top: 8px;
		}
	}

	@media($mobileS) {
		.protocol-detail-box {
			padding-top: 6px;
		}
	}
</style>