import axios from "axios";
import _ from 'lodash';
import router from "../../router";
import { Storage } from '@capacitor/storage';
import { FacebookLogin } from '@capacitor-community/facebook-login';
import { GoogleAuth } from '@codetrix-studio/capacitor-google-auth';
import i18n from "../../i18n";
import { Badge } from '@capawesome/capacitor-badge';
import { NativeBiometric } from "capacitor-native-biometric";

const auth = {
	state: {
		user: {},
		user_token: '',
		userChange: 0,
		isLoggedIn: false,
		shouldLogIn: true,
		stopAutomaticLogout: false,
		lastActiveTimestamp: 0,
		userDefaultId: false,
		intranetUser: false,
		inviteHash: false,
		inviteAgent: false,
	},
	mutations: {
		SET_USER(state, user) {
			this._vm.$set(state, 'user', user);
			state.userChange++;
		},
		SET_USER_LOGGED_IN(state) {
			state.isLoggedIn = true;
		},
		SET_SHOULD_LOGIN(state, data) {
			state.shouldLogIn = data;
		},
		SET_USER_TOKEN(state, data) {
			state.user_token = data;
		},
		TOGGLE_LOGIN_POPUP(state) {
			state.showLoginPopup = !state.showLoginPopup;
		},
		SET_INTRANET_USER(state, data) {
			state.intranetUser = data;
		},
		SET_INVITE_AGENT(state, data) {
			state.inviteAgent = data;
		},
		SET_INVITE_HASH(state, data) {
			state.inviteHash = data;
		},
		ADD_NOTIFICATION(state, data) {
			state.user.unread_notifications.unshift(data);
		},
		CLEAR_NOT_SHOWN_NOTIFICATIONS(state) {
			state.user.not_shown_notifications_count = 0;
		},
		INCREASE_NOT_SHOWN_NOTIFICATIONS(state) {
			state.user.not_shown_notifications_count++;
		},
		REMOVE_NOTIFICATION(state, id) {
			state.user.unread_notifications = _.remove(state.user.unread_notifications, (item) => item.id !== id);
		},
		SET_STOP_AUTOMATIC_LOGOUT(state) {
			state.stopAutomaticLogout = true;
		},
		UNSET_STOP_AUTOMATIC_LOGOUT(state) {
			state.stopAutomaticLogout = false;
		},
		SET_LAST_ACTIVE_TIMESTAMP(state, data) {
			state.lastActiveTimestamp = data;
		},
		SET_INTRANET_ID(state, intranetId){
			state.user.intranet_id = intranetId;
		},
	},
	actions: {
		async removeDefaultUser(context){
			await Storage.remove({ key: 'default_user_id' });
			await Storage.remove({ key: 'user_pin' });

			NativeBiometric.deleteCredentials({
				server: process.env.VUE_APP_CAP_ID_ANDROID
			});

			context.state.userDefaultId = false;

			return true;
		},

		async setDefaultUserId(context, id) {
			return await Storage.set({
				key: 'default_user_id',
				value: id.toString(),
			});
		},
		async getDefaultUserId() {
			const item = await Storage.get({
				key: 'default_user_id',
			});

			if (!item.value) {
				return false;
			}

			return item;
		},

		async checkDefaultUserLogin(context) {
			if (!context.rootState.app.native) {
				return false;
			}

			const userId = await context.dispatch('getDefaultUserId');
			context.state.userDefaultId = userId.value;
		},
		async getUser(context) {
			return new Promise((resolve, reject) => {
				context.dispatch('checkAutomaticLogoutStatus');
				if (!axios.defaults.headers.common['Authorization'] || !context.state.shouldLogIn) {
					reject();
					return;
				}

				context.commit('SET_APP_LOADING');
				axios.get('/api/user').then((response) => {
					context.commit('SET_USER', response.data.user);
					if (!response.data.user.active) {
						context.commit('OPEN_USER_ACTIVATION_POPUP');
					}
					if (!response.data.user.complete_registration) {
						context.commit('OPEN_COMPLETE_REGISTRATION_POPUP');
					}

					if (response.data.user.settings && typeof response.data.user.settings.dark_mode !== 'undefined') {
						context.commit('SET_DARK_MODE', response.data.user.settings.dark_mode);
					}

					context.commit('SET_USER_LOGGED_IN');
					context.commit('UNSET_APP_LOADING');
					resolve();
				}).catch((error) => {
					localStorage.removeItem('user_token');
					context.commit('UNSET_APP_LOADING');
					reject(error);
				});
			});
		},
		processLogin(context, response) {
			context.commit('SET_USER', response.data.user);
			if (!response.data.user.active) {
				context.commit('OPEN_USER_ACTIVATION_POPUP');
			}
			if (response.data.user.settings && typeof response.data.user.settings.dark_mode !== 'undefined') {
				context.commit('SET_DARK_MODE', response.data.user.settings.dark_mode);
			}
			if (!response.data.user.complete_registration) {
				context.commit('OPEN_COMPLETE_REGISTRATION_POPUP');
			}
			context.commit('SET_USER_LOGGED_IN');
			localStorage.setItem('user_token', response.data.user_token);
			axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.user_token}`;
			context.commit('SET_USER_TOKEN', response.data.user_token);

			if (context.rootState.app.native) {
				context.dispatch('setDefaultUserId', response.data.user.id);
				context.state.userDefaultId = response.data.user.id;
				context.dispatch('synchronizeBadges', response.data.user.not_shown_notifications_count);
			}
			if (!response.data.user.settings.contracts_imported_timestamp || ((~~(Date.now() / 1000)) - response.data.user.settings.contracts_imported_timestamp) > 3600) {
				context.dispatch('importContracts');
			}
			if (!response.data.user.settings.personal_documents_imported_timestamp || ((~~(Date.now() / 1000)) - response.data.user.settings.personal_documents_imported_timestamp) > 3600) {
				context.dispatch('importPersonalDocuments');
			}
			if (!response.data.user.settings.intranet_data_imported_timestamp || ((~~(Date.now() / 1000)) - response.data.user.settings.intranet_data_imported_timestamp) > 3600) {
				context.dispatch('importIntranetData');
			}

			router.push('/');
		},
		login(context, formData) {
			if (context.rootState.app.native) {
				formData.append('app_version', context.rootState.app.version);
			}

			return new Promise((resolve, reject) => {
				axios.post('/api/login', formData).then(response => {
					context.dispatch('processLogin', response);
					resolve();
				}).catch(error => {

					if (error?.response?.data?.message) {
						this._vm.$swal.fire({
							icon: 'error',
							title: 'Oops',
							text: error.response.data.message
						});
					}

					if (error?.response?.data?.errors) {
						this.errors = error.response.data.errors;
					}


					if(error?.response?.data?.action){
						context.dispatch('removeDefaultUser');
					}

					reject();
				});
			});
		},
		nativeLogin(context, formData) {
			formData.append('app_version', context.rootState.app.version);

			return new Promise((resolve, reject) => {
				axios.post('/api/native-login', formData).then(response => {
					context.dispatch('processLogin', response);
					resolve();
				}).catch(error => {
					this._vm.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: error.response.data.message
					});

					if (error.response.data.errors) {
						this.errors = error.response.data.errors;
					}


					if(error?.response?.data?.action){
						context.dispatch('closePopups');
						context.dispatch('removeDefaultUser');
					}

					reject(error);
				});
			});
		},
		checkAutomaticLogoutStatus(context) {
			if (!context.rootState.app.native) {
				return;
			}

			let syncIntranetId = localStorage.getItem('syncIntranetId');
			if(syncIntranetId){
				localStorage.removeItem('syncIntranetId');
				return;
			}

			let lastActiveTimestamp = localStorage.getItem('last_active_timestamp');
			if(!lastActiveTimestamp){
				return;
			}
			lastActiveTimestamp = parseInt(lastActiveTimestamp);

			let logoutTime = 120000;
			let currentTimestamp = +new Date();
			if ((currentTimestamp - lastActiveTimestamp >= logoutTime) && !context.state.stopAutomaticLogout) {
				context.commit('SET_SHOULD_LOGIN', false);
				context.dispatch('processLogOut');
			}
		},
		processLogOut(context) {
			if (!context.state.isLoggedIn) {
				return false;
			}

			if (context.state.user.facebook_user_id) {
				FacebookLogin.logout();
			}
			if (context.state.user.google_user_id) {
				if (context.rootState.app.native) {
					GoogleAuth.signOut();
				} else {
					let googleAuth = gapi.auth2.getAuthInstance();
					if (googleAuth) {
						googleAuth.signOut();
					}
				}
			}

			context.state.user = {};
			context.state.isLoggedIn = false;
			localStorage.removeItem('user_token');
			localStorage.removeItem('last_active_timestamp');
			context.dispatch('closePopups');
			context.commit('SET_VIEWS_DEFAULT_STATE');
			router.push(i18n.t('routes.landing.path'));
			context.commit('SET_SHOULD_LOGIN', true);
			context.commit('SET_DARK_MODE', true);
		},
		logout(context) {
			context.commit('SET_APP_LOADING');
			axios.get('api/logout').then(() => {
				context.dispatch('processLogOut');
				context.commit('UNSET_APP_LOADING');
			});
		},
		async synchronizeBadges(context, badgesCount) {
			let permission = await Badge.checkPermissions();
			if (permission.display !== 'granted') {
				return;
			}
			await Badge.set({ count: badgesCount });
		},
		importContracts(context) {
			axios.get('/api/contracts/check-contracts').then(() => {
				context.state.user.settings.contracts_imported = true;
				axios.post('/api/user/settings', {
					_method: 'PUT',
					name: 'contracts_imported_timestamp',
					value: ~~(Date.now() / 1000),
				});
			});
		},
		importPersonalDocuments(context) {
			axios.get('/api/personal-documents/import').then(() => {
				context.state.user.settings.personal_documents_imported = true;
				axios.post('/api/user/settings', {
					_method: 'PUT',
					name: 'personal_documents_imported_timestamp',
					value: true,
				});
			});
		},
		importIntranetData(context) {
			axios.get('/api/user/import-intranet-data').then((response) => {
				context.state.user.settings.intranet_data_imported = true;
				if (response.data.user) {
					if (response.data.user.cars && response.data.user.cars.length) {
						context.state.user.cars = response.data.user.cars;
					}
					if (response.data.user.relations && response.data.user.relations.length) {
						context.state.user.relations = response.data.user.relations;
					}
				}

				axios.post('/api/user/settings', {
					_method: 'PUT',
					name: 'intranet_data_imported_timestamp',
					value: ~~(Date.now() / 1000),
				});
			});
		}
	}
};

export default auth;